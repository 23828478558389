$globalFont: 'Poppins', sans-serif;
$globalLineHeight: 1.167;
$globalFontSizeBig: 2.0625rem;

$colorWhite: #FFFFFF;
$colorBlack: #000000;
$colorGreyLight: #C6C6C6;
$colorYellow: #FFBA00;

$accent: #9a9767;
$accentSecondary: #5c5c5c;

$background-color: #303336;
$background-dark: #1b1d1e;
$background-overlay: rgba(159, 160, 164, 0.197);
$foreground-color: rgba(255, 255, 255, 0.53);
$foreground-overlay-dark: rgba(40, 40, 40, 0.186);
$accentSecondaryLight: rgba(64, 64, 64, 0.8);
$accentLight: #adaa66;
$accentLightest: rgba(188, 186, 155, 0.306);
$accentDark: #615E36;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$label: #5F5F5F;
$label-focused: rgb(0, 171, 85);
$label-error: rgb(255, 72, 66);

$input-error-text: rgb(255, 72, 66);
$input-border: rgba(0, 0, 0, 0.2);
$input-border-focused: rgba(0, 0, 0, 0.3);
$input-border-error: rgb(255, 72, 66);

$button-disabled: rgba(145, 158, 171, 0.24);

$primary-button-background: #9a9767;
$primary-button-background-hover: #615E36;
$primary-button-shadow: rgba(97, 94, 54, 0.24) 0 6px 16px 0;

$element-drop-shadow: rgba(0, 0, 0, 0.04);
