@import './styles/abstracts/variables';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  overflow-y: scroll !important;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: $accent;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  background: $background-color;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: $accent;
  padding: 0 9px 0 0;
  display: flex;
  text-decoration: none;
  font-weight: 700;

  &:hover {
    text-decoration: none;
  }
}

.MuiDialog-root {
  .MuiBackdrop-root {
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    z-index: -1;
  }
}
